var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-center titulo"},[_vm._v("Lista de Produtos")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"footer-props":{
      'items-per-page-text': 'Produtos por página:',
      pageText: '{0}-{1} de {2}',
    },"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 btn-gold",on:{"click":_vm.newItem}},[_vm._v("Criar um novo Produto")]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"body-style d-flex justify-center"},[_vm._v("Deseja excluir este item?")]),_c('v-card-actions',{staticClass:"d-flex justify-content-around"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Excluir")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")])],1)],1)],1)],1)]},proxy:true},{key:"item.urlImagem",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('img',{staticClass:"d-flex justify-center border-color",staticStyle:{"max-width":"100px"},attrs:{"src":item.urlImagem,"alt":""}})])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"actions-inline"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('div',[_vm._v("Nenhum item cadastrado até o momento")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }